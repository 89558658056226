import { Injectable } from '@angular/core';
import { IRollupProjectTable } from '../store/rollups/rollups.interface';
import { CurrentUserService } from './current-user.service';
import { INTERCOM_ID } from '../framework/constants/general.constants';
import { Intercom } from '@supy-io/ngx-intercom';
import { ProjectTableDataContractor } from '../pages/webapp/projects/projects.interface';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(
    private intercom: Intercom,
    private user: CurrentUserService,
  ) {}

  updateIntercom(projects?: (IRollupProjectTable | ProjectTableDataContractor)[]) {
    const userData: any = {
      app_id: INTERCOM_ID,
      widget: {
        activator: '#intercom',
      },
    };

    if (this.user.isLoggedIn() && this.user.data) {
      userData.name = this.user.data.first_name + ' ' + this.user.data.last_name;
      userData.email = this.user.data.email;
      userData.created_at = this.user.data.created_at; // Signup date as a Unix timestamp
      userData.numberOfProjects = projects?.length ?? null; // num of projects
      userData.isManager = !!this?.user?.isManager;
      userData.isServiceProvider = this?.user?.isServiceProvider;
      userData.isGeneralContractor = !!this?.user?.isGeneralContractor;
    }
    this.intercom.update(userData);
  }

  bootIntercom() {
    const userData: any = {
      app_id: INTERCOM_ID,
      widget: {
        activator: '#intercom',
      },
    };

    if (this.user.isLoggedIn() && this.user.data) {
      userData.name = this.user.data.first_name + ' ' + this.user.data.last_name; // full name
      userData.email = this.user.data.email; // email address
      userData.created_at = this.user.data.created_at; // Signup date as a Unix timestamp
      userData.numberOfProjects = null; // num of projects
      userData.isManager = !!this?.user?.isManager;
      userData.isServiceProvider = this?.user?.isServiceProvider;
      userData.isGeneralContractor = !!this?.user?.isGeneralContractor;
    }
    this.intercom.boot(userData);
  }
}
